import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroPages from "../components/heroPages"
import Image from "../components/imagesComponent/contact"
import Button from "../components/button"
import { useForm } from "react-hook-form"
import {
  FaFacebookSquare as Facebook,
  FaInstagram as Instagram,
} from "react-icons/fa"
import { graphql } from "gatsby"

import { useLocaleContext } from "../context"

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

function Modal({ variant = "success", setShow = () => {} }) {
  const { locale } = useLocaleContext()
  const isSpanish = locale === "es"
  React.useEffect(() => {
    setTimeout(() => {
      setShow(false)
    }, 3000)
  }, [])
  return (
    <div className="fixed w-full h-full top-0 flex items-center justify-center bg-orange-100 bg-opacity-75 z-20">
      {variant === "success" && (
        <div className="bg-primary text-white rounded shadow-lg py-4 px-5">
          <p>
            {isSpanish
              ? "Su mensaje ha sido enviado, gracias por contactarnos"
              : "Message delivered! Thanks for contact us!"}
          </p>
        </div>
      )}
      {variant !== "success" && (
        <div className="bg-red-500 text-white rounded shadow-lg py-4 px-5">
          <p>
            {isSpanish
              ? "Upss, ocurrió un error, intente de nuevo luego"
              : "Upss, something when wrong try again later"}
          </p>
        </div>
      )}
    </div>
  )
}

export default function Contact({ data }) {
  const { barText } = data.takeshape.getTopBar
  const { locale } = useLocaleContext()
  const isSpanish = locale === "es"
  const footer = data.takeshape.getContactUsPage
  const { register, handleSubmit, errors } = useForm()
  const [show, setShow] = React.useState(false)
  const [message, setMessage] = React.useState("success")
  const myForm = React.useRef(null)

  const onSubmit = async (data, e) => {
    e.preventDefault()
    try {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "contact", ...data }),
      })
    } catch (error) {
      setMessage("error")
    } finally {
      setShow(true)
      myForm.current.reset()
    }
  }

  return (
    <Layout footer={footer} barText={barText}>
      <SEO title="Contact" />
      <HeroPages
        title={footer.title}
        text={footer.subtitle}
        image={<Image />}
      />
      {show && <Modal variant={message} setShow={setShow} />}
      <div className="container mx-auto px-5 grid md:grid-cols-2 gap-10 md:mt-16 md:mb-24 mb-10">
        <div className="space-y-6">
          <p>{footer.content}</p>
          <div>
            <p>{isSpanish ? "Síguenos:" : "Follow us:"}</p>
            <div className="flex text-2xl space-x-3 mt-2">
              {footer.facebook && (
                <a href={footer.facebook} target="blank">
                  <Facebook />
                </a>
              )}
              {footer.instagram && (
                <a href={footer.instagram} target="blank">
                  <Instagram />
                </a>
              )}
            </div>
          </div>
          <div>
            <p>{isSpanish ? "Llámenos:" : "Give us a call:"}</p>
            <div className="space-y-2 mt-3 flex flex-col">
              {footer.phones.map(({ phone }, index) => (
                <a key={index} href={`tel:${phone}`}>
                  {phone}
                </a>
              ))}
            </div>
          </div>
        </div>
        <div>
          <h2 className="text-5xl text-primary mb-5 leading-none font-serif">
            {isSpanish ? "Formulario de contacto" : "Contact form"}
          </h2>
          <form
            netlify-honeypot="last-real-name"
            onSubmit={handleSubmit(onSubmit)}
            className="space-y-8"
            ref={myForm}
          >
            <input
              type="text"
              name="name"
              placeholder={isSpanish ? "Nombre completo" : "Full Name"}
              className="border-b border-primary h-12 w-full focus:outline-none"
              ref={register({ required: true })}
            />
            {errors.name && (
              <span className="text-red-500 text-sm">
                {isSpanish
                  ? "Este campo es obligatorio"
                  : "This field is required"}
              </span>
            )}
            <input
              type="email"
              name="email"
              placeholder={isSpanish ? "Correo electrónico" : "Email address"}
              className="border-b border-primary h-12 w-full focus:outline-none"
              ref={register({ required: true })}
            />
            {errors.email && (
              <span className="text-red-500 text-sm">
                {isSpanish
                  ? "Este campo es obligatorio"
                  : "This field is required"}
              </span>
            )}
            <input
              type="phone"
              name="phone"
              placeholder={isSpanish ? "Teléfono" : "Phone number"}
              className="border-b border-primary h-12 w-full focus:outline-none"
              ref={register({ required: true })}
            />
            {errors.phone && (
              <span className="text-red-500 text-sm">
                {isSpanish
                  ? "Este campo es obligatorio"
                  : "This field is required"}
              </span>
            )}
            <textarea
              name="message"
              id="message"
              placeholder={isSpanish ? "Mensaje" : "Message"}
              rows="10"
              className="border-b block border-primary h-32 w-full focus:outline-none"
              ref={register({ required: true })}
            />
            {errors.message && (
              <span className="text-red-500 text-sm">
                {isSpanish
                  ? "Este campo es obligatorio"
                  : "This field is required"}
              </span>
            )}
            <div className="block">
              <Button tail="w-full">
                {isSpanish ? "Enviar mensaje" : "Send message"}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($locale: String) {
    takeshape {
      getContactUsPage(locale: $locale) {
        title
        subtitle
        content
        phones {
          phone
        }
        facebook
        instagram
      }
      getTopBar(locale: $locale) {
        barText
      }
    }
  }
`
